import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <header>
          <div className="container sm">
            <div className="grid">
              <div className="column-xs-12">
                <h1>Page Not Found</h1>
                <p className="lead">Sorry, but the page you were looking for could not be found.</p>
                <div className="lead-home"><Link to="/" className="page-link">Go to Home →</Link></div>
              </div>
            </div>
          </div>
        </header>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
